import { createRouter, createWebHashHistory } from "vue-router";
import homeRoutes from "../modules/home/routes";
import assetsRoutes from "../modules/assets/routes";
import SettingsModule from "../modules/settings/";
import SimpleRouteView from "@/shared/components/SimpleRouteView.vue";

const routes = [
    {
        path: "/",
        redirect: "allAssets",
        component: SimpleRouteView,
        children: [
            ...homeRoutes,
            ...assetsRoutes,
            SettingsModule
        ]
    }
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

// This navigation guard is to redirect from Keycloak's query params to the proper URL
router.beforeEach((to, from, next) => {
    if (to.fullPath.startsWith("/state")) {
        next({ name: "assets" });
    }
    else next();
});

export default router;
